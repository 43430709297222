import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { Location } from 'Models/LocationModels';

export interface ILocationSelectProps {
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The array of locations that will be shown as dropdown items */
	locations: Location[];
	/** The label/placeholder for the select element */
	label?: string;
	/** Classes to be passed to the component */
}

/** Shows active locations */
export const LocationSelect = (props: ILocationSelectProps) => {
	return (
		<Field component={TextField} name={props.name} select={true} label={props.label || 'Location'} fullWidth={true}>
			<MenuItem key={`Location-select-none`} value="0">
				- Select -
			</MenuItem>
			{props.locations
				.filter(a => a.active)
				.sort((a, b) => a.name.localeCompare(b.name))
				.map(a => {
					return (
						<MenuItem key={`Location-select-${a.id}`} value={a.id}>
							{a.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
